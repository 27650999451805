import React from 'react'
import { GlobalStyles } from '@mui/material'
import { Box, Card } from '@mui/material'
import axios from 'axios'
import { ComparisonResult } from './ComparisonResult'
import { useParams } from 'react-router-dom'

//This page opens in a new tab separate from the main application. Based on the path parameter it either
//reaches out to a single robot or a group of robots and requests their results.JSON file which is uses to
//create a list of <ComparisonResult> cards.
export const ResultsPage = () => {
  const [error, setError] = React.useState()
  const [resultsArray, setResultsArray] = React.useState()

  const params = useParams()
  const robotAddress = process.env.REACT_APP_ROBOT_ADDRESS || 'http://localhost'

  React.useEffect(() => {
    let robotsList = [] //This will become the list of robots that we need to ping to get their results.
    let isGroup = false //This is a boolean value that is used to determine if the param.name is a group name or a robot name

    const fetchResults = async () => {
      try {
        const { data: robots } = await axios(`${robotAddress}:5000/robots`)

        //Search through all the groups checking their names to see if the groupname matches the param.name.
        robots.forEach((group) => {
          if (group.name === params.name) {
            isGroup = true //We know it is a group name, so we set this flag.
            robotsList = robots[robots.indexOf(group)].robots
          }
        })

        if (!isGroup) {
          //The param.name is a robotname
          //Here we need to iterate over all the groups again, but then also iterate over each group's list of robots to check each of the individual robots names.
          robots.forEach((group) =>
            group.robots.forEach((robot) => {
              if (robot.hostname === params.name) {
                robotsList = [{ hostname: robot.hostname, address: robot.address }]
              }
            })
          )
        }

        const promises = [] //This is the array of promises that will be filled and called later.
        const newResultsArray = [] //As we get results back from the axios requests this array will be filled. Eventually we will set the resultsArray based on this.
        robotsList.forEach((robot) => {
          promises.push(
            //This entire .then() block returns a promise. This is used so that we can promise this functionality to occure after the response comes.
            //The await Promise.all function then will wait for both the axios.get() and the .then() to complete before proceeding.
            axios.get(`http://${robot.address}:5000/testing/results`).then((response) => {
              //This takes all of the returned results and adds the robots IP and hostname to the JSON objects. Because we might be getting results back from different
              //robots with the same name, this is necessary to determine just exactly what robot took which picture and made which comparison.
              response.data.forEach((comparison) => {
                response.data[response.data.indexOf(comparison)] = Object.assign(
                  response.data[response.data.indexOf(comparison)],
                  {
                    hostname: robot.hostname,
                    address: robot.address,
                  }
                )
                newResultsArray.push(comparison)
              })
            })
          )
        })
        await Promise.all(promises) //After this has resolved, all of the processed 'comparisons' will be pushed to the newResultsArray
        setResultsArray(newResultsArray)
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      fetchResults()
    }
  }, [error, params, robotAddress])

  return (
    <div className="ResultsPage">
      {/* This GlobalStyle is inclded to change the background color of the page to look like that of .pdf file in chrome */}
      <GlobalStyles
        styles={{
          body: { backgroundColor: '#525659' },
        }}
      />
      {/* The margins on this <Box> are temporary. We need to look into something that will actually resemble the width of a piece of paper so that it can be printable eventually. */}
      <Box mt={2} ml={30} mr={30}>
        <Card elevation={12} square={true}>
          <div class="container">
            {/* For each of the results in the resultsArray, make a new comparison <ComparisonResult> component. */}
            {!!resultsArray &&
              resultsArray.map((comparison) => (
                <ComparisonResult key={comparison.imageName} comparison={comparison} />
              ))}
          </div>
        </Card>
      </Box>
    </div>
  )
}
