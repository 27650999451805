import * as React from 'react'
import { VideoStream } from './VideoStream.jsx'
import { EditConfigurationButton } from './EditConfigurationButton.jsx'
import { DeleteRobotButton } from './DeleteRobotButton.jsx'
import { RobotResultsButton } from './RobotResultsButton.jsx'
import { CalibrateButton } from './CalibrateButton.jsx'
import { CaptureImageButton } from './CaptureImageButton.jsx'
import { ViewLogsButton } from './ViewLogsButton.jsx'
import { ConnectDeviceButton } from './ConnectDeviceButton.jsx'
import ErrorAlert from './ErrorAlert'
import axios from 'axios'
import { Card, Grid, Box } from '@mui/material'
import { CircularProgress } from '@mui/material'
import useInterval from './useInterval.js'

// This is a robot that displays its name, address, and current config as well as a video stream of the camera on the
// robot. It includes 4 buttons, EditConfiguration, Calibrate, Delete Robot and View Results
export const Robot = ({ robot, groupList, setGroupList }) => {
  const [config, setConfig] = React.useState()
  const [error, setError] = React.useState()
  const pageUrl = robot.address
  const streamHeight = '180' //This height is passed into the VideoStream and Calibration components to size the UI
  const streamWidth = '240' //This width is passed into the VideoStream and Calibration components to size the UI
  const pingDelayMs = 10 * 1000 // (10 Seconds) This is how many seconds we wait before pinging each robot to see if it is online or offline.

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        //This overrides the current robotsList of the new robot with the list that is currently in use.
        const { data: config } = await axios.get(`http://${pageUrl}:5000/testing/config`)
        setConfig(config) //Set the config value if we receive the config back from the robot.
        await axios.put(`http://${pageUrl}:5000/robots`, groupList)
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      fetchData()
    }
  }, [pageUrl, error, groupList])

  //Pings the robot and updates the config of the robot based on what is returned. The config variable is used as a sort of "Exists/Doesn't Exist" boolean
  //This makes is so that if the robot goes offline it will be displayed that way, and if the robot comes back online it will refresh itself.
  const pingRobot = async () => {
    try {
      const { data: config } = await axios.get(`http://${pageUrl}:5000/testing/config`)
      setConfig(config) //Set the config value if we receive the config back from the robot.
      setError() //If there is no error we clear the error
    } catch (err) {
      setError(err.message) //If there is an error we set error to that error.
      setConfig() //If there was an error then our config is outdated. Set it to blank.
    }
  }

  // This calls the pingRobot function every pingDelay seconds
  useInterval(() => pingRobot(), pingDelayMs)

  return (
    <div className="Robot">
      <ErrorAlert error={error} setError={setError} />
      <Box m={2}>
        <Card elevation={5}>
          <Box ml={2} mr={2} mb={2}>
            <Grid container>
              {/* This displays the robot hostname, address and current config as a header to the card */}
              <Grid item xs={12} container justifyContent="flex-start">
                <h3>
                  {' '}
                  {robot.hostname} - {robot.address}
                  {!!config && ` - ${config.model}/${config.suite}/${config.subconfig}`}
                </h3>
              </Grid>

              <Grid item xs={6} container spacing={2} justifyContent="flex-start">
                {/* These are the 4 main buttons on the robot. */}
                {!!config && (
                  <Grid item xs={8}>
                    <EditConfigurationButton robot={robot} config={config} setConfig={setConfig} />
                  </Grid>
                )}

                {!!config && (
                  <Grid item xs={8}>
                    <CalibrateButton robot={robot} config={config} setConfig={setConfig} />
                  </Grid>
                )}

                {!!config && (
                  <Grid item xs={8}>
                    <ViewLogsButton robot={robot} />
                  </Grid>
                )}

                {/* This is always displayed. Even if the robot is offline the user has the option to delete it. */}
                <Grid item xs={8}>
                  <DeleteRobotButton
                    address={robot.address}
                    groupList={groupList}
                    setGroupList={setGroupList}
                  />
                </Grid>

                {!!config && (
                  <Grid item xs={8}>
                    <RobotResultsButton robot={robot} />
                  </Grid>
                )}
              </Grid>

              {/* This is the styling for the video stream component. Notice how it is encapsulated in a 
              div with position relative. This is neccessary for the plus symbol on the stream to be centered. */}
              <Grid item xs={6} container justifyContent="flex-end">
                <ConnectDeviceButton robot={robot} setError={setError} />

                <Grid item xs={12} container justifyContent="flex-end">
                  {!!config && (
                    <div style={{ position: 'relative', height: streamHeight + 'px' }}>
                      <VideoStream
                        robot={robot}
                        streamHeight={streamHeight}
                        streamWidth={streamWidth}
                      />
                      <Grid
                        item
                        xs={12}
                        container
                        style={{
                          position: 'absolute',
                          top: '0',
                        }}
                      >
                        <CaptureImageButton robot={robot} setError={setError} />
                      </Grid>
                    </div>
                  )}
                </Grid>
                {/* This is displayed if the robot isn't online instead of the stream. */}
                {!config && <CircularProgress size={180} />}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </div>
  )
}
