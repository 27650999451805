import * as React from 'react'
import axios from 'axios'
import { IconButton } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import moment from 'moment'

export const CaptureImageButton = ({ robot, setError }) => {
  const captureImage = async () => {
    // Moment is used for capturing the date. The format is used to record the date and time down to the second to make each image captured have a unique name.
    const date = moment().format('D-M-YYYY h:mm s')

    //These are the params that are needed to be passed into the ImagePage.jsx component in the format of a JSON object. It is passed as a string and parsed into JSON later.
    const params = `{"address": "${robot.address}", "hostname": "${robot.hostname}", "date": "${date}"}`
    console.log(params)
    try {
      await axios.post(`http://${robot.address}:5000/testing/captureImage/${date}`)
      const win = window.open(`/image/${params}`, '_blank')
      if (win != null) {
        win.focus()
      }
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <IconButton variant="contained" onClick={captureImage}>
      <CameraAltIcon sx={{ color: 'white' }} />
    </IconButton>
  )
}
