import * as React from 'react'
import axios from 'axios'
import ErrorAlert from './ErrorAlert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DialogTitle from '@mui/material/DialogTitle'

//Given that a robot is set with a valid configuration, this component will display a popup asking for a Firmware file to upload to the raspberry pi and begin a test with.
export const RunTestsButton = ({ robots }) => {
  const [open, setOpen] = React.useState(false) //This indicates that the window is initially closed (open = false). This is set to true when the button is clicked.
  const [selectedFile, setSelectedFile] = React.useState(null) //This is the selectedFile variable use for storing and sending the .frm file to the robot.
  const [error, setError] = React.useState()

  //This is used to open the popup window when the button is clicked.
  const handleClickOpen = () => {
    setOpen(true)
  }

  //This is used to close the popup window when a user clicks somewhere off of the popup window or when a test is started/
  const handleClose = () => {
    setOpen(false)
  }

  //This sends the selected firmware file to all of the robots in the group.
  //TODO: Ensure that you cannot close send a request if a firmware file isn't selected
  const submitForm = async () => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    try {
      await robots.map((robot) =>
        axios.post(`http://${robot.address}:5000/testing/firmware`, formData)
      )
    } catch (err) {
      setError(err.message)
    }
    if (!error) {
      handleClose()
    }
  }

  return (
    <div>
      <ErrorAlert error={error} setError={setError} />
      {/* This is the basic button that is displayed on the RobotsGroup component */}
      <Button
        onClick={handleClickOpen}
        startIcon={<PlayArrowIcon />}
        fullWidth
        color="primary"
        variant="contained"
      >
        {' '}
        Run Tests{' '}
      </Button>
      {/* This is the dialog that appears when the run tests button is clicked. */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Select Firmware File'}</DialogTitle>
        <DialogContent>
          {/* This is the <form> component that is used for letting the user upload a .frm file from their computer */}
          <form>
            <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
          </form>
        </DialogContent>
        <DialogActions>
          {/* This button is used to upload the selected .frm file to the robots and initialize a test. 
          This will run a test based on what config is currently selected on each robot, and what 
          firmware file is uploaded in the dialog above. */}
          <Button variant="contained" onClick={submitForm} autoFocus>
            Run
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
