import React from 'react'
import { GroupList } from './components/GroupList.jsx'
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// This is a global theme that allows us to style buttons and other things with color={primary} without them being hardwired to individual colors.
const theme = createTheme({
  palette: {
    primary: {
      main: '#42a5f5', //Custom Blue
    },
    secondary: {
      main: '#ff9800', //Custom Orange
    },
    tertiary: {
      main: '#BF40BF', //Custom Purple
    },
  },
})

// This is hardwiring the robot address to an environment variable on each user's host machine. It also allows the use of a docker file to run and have access to the backend.
const robotAddress = process.env.REACT_APP_ROBOT_ADDRESS || 'http://localhost'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {/* This GroupList is the main parent component of the app. */}
        <GroupList robotAddress={robotAddress} />
      </div>
    </ThemeProvider>
  )
}

export default App
