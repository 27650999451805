import * as React from 'react'
import axios from 'axios'
import { IconButton } from '@mui/material'
import UsbIcon from '@mui/icons-material/Usb'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid } from '@mui/material'

export const ConnectDeviceButton = ({ robot, setError }) => {
  const [connected, setConnected] = React.useState(false)
  const [connecting, setConnecting] = React.useState(false)
  const [deviceSerialNumber, setDeviceSerialNumber] = React.useState()
  const ConnectDevice = async () => {
    try {
      setConnected(false)
      setConnecting(true)
      const { data: result } = await axios.get(`http://${robot.address}:5000/initialize_device`)
      const { data: serialNumber } = await axios.get(
        `http://${robot.address}:5000/get_device_serial`
      )
      setDeviceSerialNumber(serialNumber)
      if (result === 'OK') {
        setConnected(true)
      }
      setConnecting(false)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <Grid container item xs={5} justifyContent="flex-end" alignItems="center">
      <Grid item xs={9}>
        <p> {deviceSerialNumber} </p>
      </Grid>
      <Grid item xs={1.5}>
        <IconButton variant="contained" onClick={ConnectDevice}>
          <UsbIcon style={connected ? { color: '#2e7d32' } : { color: '#d32f2f' }} />
        </IconButton>
      </Grid>
      <Grid item xs={1.5}>
        {!!connecting && <CircularProgress color="primary" size={20} />}
      </Grid>
    </Grid>
  )
}
