import React from 'react'
import { Card, Grid, Box } from '@mui/material'

//This is mostly just temporary formatting for the comparison results page. It doesn't look great but it does display everything we need.
//Important things are that for each image name, the backend will host an 'Expected_imageName_.jpg' and a 'Captured_imageName_.jpg'
//so two different paths for images can be generated for two <Img> tags
export const ComparisonResult = ({ comparison }) => {
  const imageWidth = 400
  const imageHeight = 300

  return (
    <div className="ComparisonResult">
      <Box mb={2}>
        <Card elevation={5}>
          <Box m={2}>
            {/* This is the header with the hostname and address */}
            <Grid container justifyContent="center">
              <p class="font-weight-light">
                Results from: {comparison.hostname} - {comparison.address}
              </p>
            </Grid>

            {/* This is the label for each of the images */}
            <Grid container>
              <Grid item xs={4} container justifyContent="center">
                <p>Expected_{comparison.imageName}</p>
              </Grid>
              <Grid item xs={4} container justifyContent="center">
                <p>Captured_{comparison.imageName}</p>
              </Grid>
            </Grid>

            {/* This is for both of the images. Expected and Captured */}
            <Grid container>
              <Grid item xs={4} container justifyContent="center">
                <img
                  src={`http://${comparison.address}:5000/testing/getImage/Expected_${comparison.imageName}.jpg`}
                  width={imageWidth}
                  height={imageHeight}
                  border="1"
                  alt="Expected img"
                />
              </Grid>
              <Grid item xs={4} container justifyContent="center">
                <img
                  src={`http://${comparison.address}:5000/testing/getImage/Captured_${comparison.imageName}.jpg`}
                  width={imageWidth}
                  height={imageHeight}
                  border="1"
                  alt="Captured img"
                />
              </Grid>
              {/* This displays the comparisonResult, which is a string generated by the backend when it is done comparing the images. */}
              <Grid
                item
                xs={4}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item container>
                    <Grid item xs={12} m={2} container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Image to Image Result:
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          m={2}
                          // If the comparison is a 'match' then it is colored green, if not is is colored red.
                          style={
                            comparison.imageToImageResult.includes('Match')
                              ? { backgroundColor: '#2e7d32' }
                              : { backgroundColor: '#d32f2f' }
                          }
                        >
                          <p
                            item
                            style={{
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {comparison.imageToImageResult}
                          </p>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Difference:
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {Number(comparison.imageToImageDifference).toFixed(3)}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} m={2} container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Image to Text Result:
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          item
                          // If the comparison is a 'match' then it is colored green, if not is is colored red.
                          style={
                            comparison.imageToTextResult.includes('Match')
                              ? { backgroundColor: '#2e7d32' }
                              : { backgroundColor: '#d32f2f' }
                          }
                        >
                          <p
                            item
                            style={{
                              color: 'white',
                              textAlign: 'center',
                            }}
                          >
                            {comparison.imageToTextResult}
                          </p>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Image text:
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        {' '}
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {comparison.imageToTextString}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </div>
  )
}
