import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

function ErrorAlert({ error, setError, variant }) {
  if (variant === 'dialog') {
    return (
      <Dialog open={!!error} maxWidth="sm" fullWidth>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <ErrorOutlineIcon color="error" />
          &nbsp;&nbsp;Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setError(null)
            }}
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  if (variant === 'snackbar') {
    return (
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 90, sm: 0 }, maxWidth: 'lg' }}
      >
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(null)
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      </Snackbar>
    )
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={!!error}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(null)
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      </Collapse>
    </Box>
  )
}

ErrorAlert.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  variant: PropTypes.string,
}

export default ErrorAlert
