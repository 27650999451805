import * as React from 'react'
import axios from 'axios'
import ErrorAlert from './ErrorAlert'
import { updateGroupListsOnRobots } from './Utils.js'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import AddIcon from '@mui/icons-material/Add'
import DialogTitle from '@mui/material/DialogTitle'
import { Grid } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

// This is a button that opens a dialogue which allows the user to ping a robot, and if it exists, add it to the group of robots and update all the groupLists.
// One the dialogue is open, the "Ping" button will not be clickable until the user types something in the text box. Hopefully later on we can parse this so that we only accept IP addresses.
// In order for the "Add" button to show up, the user needs to successfully ping a robot. Once a robot has been pinged a green checkmark will appear, in addition to the Add button now being visible.
export const AddRobotButton = ({ group, groupList, setGroupList, updateGroupListOnRobots }) => {
  const [open, setOpen] = React.useState(false) //whether or not the dialogue is open
  const [error, setError] = React.useState()
  const [newAddress, setNewAddress] = React.useState() //The new address of the robot to be added.
  const [newHostname, setNewHostname] = React.useState() //The new hostname of the robot to be added.

  const handleClickOpen = () => {
    setOpen(true)
    setNewHostname() //Whenever we open the dialogue, we need to clear the hostname variable.
    setError() //Also clear any error that may have occurred when dealing with IP addresses on a page that we closed.
  }

  const handleClose = () => {
    setOpen(false)
  }

  //This pings the robot from the user input address (newAddress) and if we are able to see it, it saves it's hostname.
  //newHostname is then used as a variable to indicate whether or not the ping was successful.
  const pingAddress = async () => {
    try {
      const { data: hostname } = await axios.get(`http://${newAddress}:5000/testing/hostname`)
      setNewHostname(hostname)
    } catch (err) {
      setError(err.message)
      setNewHostname() //If there was an error, clear the hostname.
    }
  }

  // Once a robot has been pinged and the "Add" button is clicked, this will run. It will add
  const submitForm = async () => {
    const newGroupList = groupList

    newGroupList[newGroupList.indexOf(group)].robots.push({
      address: newAddress,
      hostname: newHostname,
    })

    try {
      // The function updateGroupListsOnRobots() returns a list of promises and needs to be called within an async function, with a Promise.all
      await Promise.all(updateGroupListsOnRobots(newGroupList))

      //Get the config again so that we don't de-sync with the devices.
      const { data: robots } = await axios.get(`http://${newAddress}:5000/robots`)

      // Set the group list on the frontend with the one on the robot, which should be updated.
      setGroupList(robots)
    } catch (err) {
      setError(err.message)
    }
    if (!error) {
      handleClose()
    }
  }

  return (
    <div className="AddRobotButton">
      {/* This is the main button that opens the dialogue */}
      <Button
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
        fullWidth
        color="secondary"
        variant="contained"
      >
        {' '}
        Add Robot{' '}
      </Button>
      {/* This is the main dialogue that opens when the "Add Robot" button is clicked. */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Add Robot to ({group.name})</DialogTitle>
        <ErrorAlert error={error} setError={setError} />
        <DialogContent>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            {/* This is telling the user to enter an IP address */}
            <Grid item xs={2}>
              <p>IP Address:</p>
            </Grid>
            {/* This is the input form which on a change sets the new address to the user input. */}
            {/* We will eventually parse these inputs and only enable the user to ping the address if the user entry is an IP address */}
            <Grid item xs={4}>
              <form>
                <input
                  type="text"
                  placeholder="Example: 192.168.0.255"
                  onChange={(e) => setNewAddress(e.target.value)}
                />
              </form>
            </Grid>
            {/* This is the ping robot button. It is disabled whenever the newAddress string is still empty. Whenever the user begins typing it becomes enabled. */}
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={!newAddress}
                onClick={pingAddress}
              >
                Ping
              </Button>
            </Grid>
            {/* A green checkmark that is disabled unless we are able to successfully ping a robot */}
            {!!newHostname && (
              <Grid item xs={2}>
                <CheckIcon color="success" />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* This is a submit button that adds the robot. This is disabled unless we have already pinged a robot and saved it's hostname. This keeps us from adding robots that don't exist. */}
          <Button variant="contained" onClick={submitForm} autoFocus disabled={!newHostname}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
