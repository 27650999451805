import React from 'react'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

//This is a simple circular loading icon that can be used in any scenario when you want
//to indicate a component is actively waiting for something like processing or an HTTP request.
const TableLoading = () => {
  return (
    <Grid container>
      <Grid item sx={{ mt: 40 }} xs={12} align="center">
        <CircularProgress color="primary" size={160} />
      </Grid>
    </Grid>
  )
}

export default TableLoading
