import React from 'react'

//This is used for creating delays in between function calls. The callback function is the function
// that is ran, and the delay is the number of milliseconds to delay for.
const useInterval = (callback, delay) => {
  //---------------------------------------------------------------------------
  // Save the callback for later use
  //---------------------------------------------------------------------------
  const savedCallback = React.useRef()
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  //---------------------------------------------------------------------------
  // This useEffect handles creation and (importantly) cleanup of the interval
  //---------------------------------------------------------------------------
  React.useEffect(() => {
    const tick = () => savedCallback.current()
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return () => {}
  }, [callback, delay])
}

export default useInterval
