import * as React from 'react'
import Button from '@mui/material/Button'
import axios from 'axios'

export const PatientSimulatorIncrementButton = ({ address }) => {
  const pressButton = async () => {
    console.log(address)
    await axios.get(`http://${address}:5000/patientSimulator/increment`)
  }

  return <Button onClick={pressButton}>Increment</Button>
}
