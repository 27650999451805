import * as React from 'react'
import Button from '@mui/material/Button'

//This button opens a new page that displays the most recent logs that are stored on the robot.
export const ViewLogsButton = ({ robot }) => {
  const OpenLogsPage = async () => {
    // const { data: logFile } = await axios.get(`http://${address}:5000/get_logs`)
    // console.log(logFile)

    //These are the params that are needed to be passed into the ImagePage.jsx component in the format of a JSON object. It is passed as a string and parsed into JSON later.
    const params = `{"address": "${robot.address}", "hostname": "${robot.hostname}"}`
    console.log(params)
    try {
      const win = window.open(`/logs/${params}`, '_blank')
      if (win != null) {
        win.focus()
      }
    } catch (err) {}
  }

  return (
    <Button fullWidth color="tertiary" variant="contained" onClick={OpenLogsPage}>
      View Logs
    </Button>
  )
}
