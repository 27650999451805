import * as React from 'react'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorAlert from './ErrorAlert'
import { updateGroupListsOnRobots, getGroupList } from './Utils.js'

//This button deletes the current robot and updates the robotsList of all the other robots that are online.
export const DeleteRobotButton = ({ address, groupList, setGroupList }) => {
  const [error, setError] = React.useState()

  const submitForm = async () => {
    const newGroupList = groupList

    //This finds the robot that we are deleting and removes it from the groupList to make the newGroupList
    newGroupList.forEach((group) =>
      group.robots.forEach((robot) => {
        if (robot.address === address) {
          newGroupList[groupList.indexOf(group)].robots.splice(group.robots.indexOf(robot), 1) //The 1 here indicates that we will just be removing or "splicing" this one character out of the array of robots.
        }
      })
    )

    //This sends out the new group list to all of the available robots
    try {
      //This sends out the newGroupList to all of the available robots
      await Promise.all(updateGroupListsOnRobots(newGroupList))

      //Gets the groupList from the robot and updates the current groupList.
      //This is so that the robots and website are always synchronized.
      getGroupList(setGroupList)
    } catch (err) {
      setError(err.message)
    }
  }

  // Returns a button with the above functionality.
  return (
    <div className="DeleteRobotButton">
      <ErrorAlert error={error} setError={setError} />
      {!error && (
        <Button
          startIcon={<DeleteIcon />}
          fullWidth
          color="error"
          variant="contained"
          onClick={submitForm}
        >
          {' '}
          Delete Robot{' '}
        </Button>
      )}
    </div>
  )
}
