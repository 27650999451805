import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ResultsPage } from './components/ResultsPage.jsx'
import { ImagePage } from './components/ImagePage.jsx'
import { LogsPage } from './components/LogsPage.jsx'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

ReactDOM.render(
  <Router>
    <Routes>
      {/* This is the path to the main app. */}
      <Route
        path="/"
        element={
          <React.StrictMode>
            <App />
          </React.StrictMode>
        }
      />
      {/* This is the path to the results. This is opened in a new tab from either <RobotResultsButton> or <GroupResultsButton>. 
      Consequently then, the name that is passed as a param, can be either a robot name or a group name. <ResultsPage> is equipped to parse both.*/}
      <Route path="/results" element={<ResultsPage />}>
        <Route path=":name" element={<ResultsPage />} />
      </Route>
      {/* This is the path to view an image specified by the name. This is opened in a new tab from the the <VideoStream> component.*/}
      <Route path="/image" element={<ImagePage />}>
        <Route path=":params" element={<ImagePage />} />
      </Route>
      <Route path="/logs" element={<LogsPage />}>
        <Route path=":params" element={<LogsPage />} />
      </Route>
    </Routes>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
