import * as React from 'react'
import axios from 'axios'
import ErrorAlert from './ErrorAlert'
import TableLoading from './TableLoading'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MemoryIcon from '@mui/icons-material/PlayArrow'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Grid } from '@mui/material'

// This button fetches the device configurations present on the robot and then lets the user select the model, suite and
// subconfig for the test that they want to run. The menu items for the user to chose for the model are based directly on
// the deviceModel that is sent back from the robot. The menu items for the suite however updates whenever the user selects
// a different model, as each model can have differently named suites. For each menu item of the subconfigs, there is only
// options 0-4. If we at some later point want to change this we can. But as long as they are numbered and coded differently
// this should be fine for differentiating which test exactly is being run.
export const EditConfigurationButton = ({ robot, config, setConfig }) => {
  const subconfigs = ['0', '1', '2', '3', '4']
  const [deviceModels, setDeviceModels] = React.useState()
  const [error, setError] = React.useState()
  const robotAddress = robot.address
  const [open, setOpen] = React.useState(false)
  const [selectedModel, setSelectedModel] = React.useState(config.model)
  const [selectedSuite, setSelectedSuite] = React.useState(config.suite)
  const [selectedSubconfig, setSelectedSubconfig] = React.useState(config.subconfig)

  // This fetches the device models from the robot
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios(`http://${robotAddress}:5000/devices/models`)
        setDeviceModels(response)
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      fetchData()
    }
  }, [robotAddress, error])

  //This opens the dialogue when the main button is clicked.
  const handleClickOpen = () => {
    setOpen(true)
  }

  //If the save button is clicked, this is called. Open is also set to close when the user clicks anywhere that isn't on the dialogue.
  const handleClose = () => {
    setOpen(false)
  }

  //If the user selects a new model from the dropdown menu, this updates the state of the current configuration.
  const handleChangeModel = (event) => {
    setSelectedModel(event.target.value)
  }

  //If the user selects a new suite from the dropdown menu, this updates the state of the current configuration.
  const handleChangeSuite = (event) => {
    setSelectedSuite(event.target.value)
  }

  //If the user selects a new subconfig from the dropdown menu, this updates the state of the current configuration.
  const handleChangeSubconfig = (event) => {
    setSelectedSubconfig(event.target.value)
  }

  //Changes to the robot aren't submitted until the user hits the "Save" button. This patches the robot with the updated
  //config and then fetches it again to ensure that the web page is always up to date with the robot.
  const submitForm = async () => {
    const newConfig = {
      model: selectedModel,
      suite: selectedSuite,
      subconfig: selectedSubconfig,
      xCal: config.xCal,
      yCal: config.yCal,
      zCal: config.zCal,
      brightness: config.brightness,
      saturation: config.saturation,
      gamma: config.gamma,
    }

    try {
      await axios.patch(`http://${robotAddress}:5000/testing/config`, newConfig) //Send the selected config to the device
      const { data: testingConfig } = await axios.get(`http://${robotAddress}:5000/testing/config`) //Get the newly set config from the device.
      // (This ensures the page and device are never out of sync)
      setConfig(testingConfig)
    } catch (err) {
      setError(err.message)
    }
    if (!error) {
      handleClose()
    }
  }

  return (
    <div className="EditConfigurationButton">
      <ErrorAlert error={error} setError={setError} />
      {!deviceModels && !error && <TableLoading />}

      {/* This is the button that is displayed and if clicked opens the dialogue bellow. */}
      {!!deviceModels && (
        <Button
          onClick={handleClickOpen}
          startIcon={<MemoryIcon />}
          fullWidth
          color="primary"
          variant="contained"
        >
          {' '}
          Edit Configuration{' '}
        </Button>
      )}
      {/* This is the dialogue that opens when the main button is clicked. */}
      {!!deviceModels && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Edit Robot Configuration'}</DialogTitle>
          <DialogContent>
            <Grid container>
              {/* This is the Select a Model dropdown menu. */}
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-helper-label">Model</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedModel}
                    label="Model"
                    onChange={handleChangeModel}
                  >
                    {/* This creates a menu item for each model within deviceModels. As this options the options for the suite are updated as well. */}
                    {Object.keys(deviceModels).map((model) => (
                      <MenuItem key={model} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* This is the Select a Suite dropdown menu. */}
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-helper-label">Suite</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedSuite}
                    label="Suite"
                    onChange={handleChangeSuite}
                  >
                    {/* This creates a menu item for each suite underneath the model. This should get updated whenever the selectedModel changes. */}
                    {deviceModels[selectedModel]?.map((testSuite) => (
                      <MenuItem key={testSuite.suite} value={testSuite.suite}>
                        {testSuite.suite}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* This is the Select a Subconfig dropdown menu. */}
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-helper-label">Subconfig</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedSubconfig}
                    label="Subconfig"
                    onChange={handleChangeSubconfig}
                  >
                    {/* This creates a menu item for each item within the subconfigs array. At this point those values are hardwired to the numbers 0-4 */}
                    {subconfigs.map((subconfig) => (
                      <MenuItem key={subconfig} value={subconfig}>
                        {subconfig}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            {/* This is the save button that is used to "submit" the results to the robot and save the current config to the robot. 
            If the save is correct you should see the robot itself update with the newly chosen subconfig. */}
            <Button variant="contained" onClick={submitForm} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
