import * as React from 'react'
import ErrorAlert from './ErrorAlert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import AddIcon from '@mui/icons-material/Add'
import DialogTitle from '@mui/material/DialogTitle'
import { Grid, Box } from '@mui/material'
import { updateGroupListsOnRobots, getGroupList } from './Utils.js'

//This component is a button that lets the user enter a name for a group and add'
//it to the groupList. Once submitted, the button will reach out to all the robots to update their lists.
export const AddGroupButton = ({ groupList, setGroupList }) => {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState()
  const [newGroupName, setNewGroupName] = React.useState()

  const handleClickOpen = () => {
    setOpen(true)
    setNewGroupName() //Clear the group name whenever it is opened again.
  }

  const handleClose = () => {
    setOpen(false)
  }

  //Once the group name is typed, a user can hid the add button and run this which adds the group to the grouplist and pushes it out to all of the robots.
  const submitForm = async () => {
    const newGroupList = groupList

    //This creates the necessary JSON format for the new group. Each group has to have a groupName, and then an array of robots.
    newGroupList.push({ name: newGroupName, robots: [] })

    try {
      //This sends out the newGroupList to all of the available robots
      await Promise.all(updateGroupListsOnRobots(newGroupList))

      //Gets the groupList from the robot and updates the current groupList.
      //This is so that the robots and website are always synchronized.
      getGroupList(setGroupList)

      handleClose()
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <div className="AddGroupButton">
      {/* This box appears at the bottom of the groupList with a single button to press. */}
      <Box ml={3} mr={2} mt={5}>
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            {/* This is the button that opens the add group dialogue */}
            <Button
              onClick={handleClickOpen}
              startIcon={<AddIcon />}
              fullWidth
              color="primary"
              variant="outlined"
            >
              {' '}
              Add Group{' '}
            </Button>
            {/* This is the dialogue that opens and allows the user to input a new group name. */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="alert-dialog-title">Add Group</DialogTitle>
              <ErrorAlert error={error} setError={setError} />
              <DialogContent>
                <Grid>
                  {/* This is prompting the user to enter a group name */}
                  <Grid>
                    <p>Group Name:</p>
                  </Grid>
                  {/* This is the form that allows the user to enter a name for the new group. Something needs to be typed here before they are able to hit the "Add" button.*/}
                  <Grid>
                    <form>
                      <input
                        type="text"
                        placeholder="Example: Trident 40"
                        onChange={(e) => setNewGroupName(e.target.value)}
                      />
                    </form>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {/* Unless the user has typed something, this button is disabled. Basically this keeps a group from being added with a null name. */}
                <Button variant="contained" onClick={submitForm} autoFocus disabled={!newGroupName}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
