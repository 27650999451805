import * as React from 'react'
import axios from 'axios'
import { Grid } from '@mui/material'
import PlusIcon from '../PlusIcon.png'

//This component should be wrapped in a div with it's position set to "relative" so that the streamHeight and streamWidth components can be used as absolute positions.
export const VideoStream = ({ robot, streamHeight, streamWidth }) => {
  const [error, setError] = React.useState()
  const [isInitialized, setIsInitialized] = React.useState(false)

  //Tells the robot to initialize the stream if it hasn't already.
  React.useEffect(() => {
    const initialize = async () => {
      try {
        const { data: result } = await axios.get(`http://${robot.address}:5000/stream/init`)
        setIsInitialized(result)
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      initialize()
    }
  }, [error, robot])

  return (
    !!isInitialized && ( //This makes sure that the component has been initialized on the backend before rendering the <div>
      <div>
        {/* This <img> is a stream from the camera on attached to the Raspberry Pis */}
        <img
          src={`http://${robot.address}:5000/stream`}
          width={streamWidth}
          height={streamHeight}
          alt="Camera stream"
        />
        {/* This <Grid> and it's styling is used for positioning the plus symbol <AddIcon> in the center of the video stream component. */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: streamHeight / 6,
            bottom: '0',
            height: streamHeight,
            width: streamWidth,
          }}
        >
          {/* This is the plus symbol component */}
          <img
            src={PlusIcon}
            style={{ width: streamHeight / 6, height: streamHeight / 6 }}
            alt="Plus Icon"
          />
        </Grid>
      </div>
    )
  )
}
