import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

//This is a page that when passed a file name, reaches out to the robot and displays the image
export const ImagePage = () => {
  //The name of the image file is the date the image was taken which is passed in as a parameter.

  //The parameter string contains all of the parameters that we want. We can parse them into JSON.
  //The format of params is:
  // {
  //   "address": The robot address,
  //   "hostname": The robot hostname,
  //   "date": The date the photo was taken including seconds
  // }

  const params = JSON.parse(useParams().params)

  return (
    <Grid m={3}>
      {/* Title above the image which displays the address and hostname that the image came from. */}
      <Grid item xs={12} container justifyContent="center">
        <p>
          Image from {params.hostname} at {params.address}
        </p>
      </Grid>
      {/* The image itself */}
      <Grid item xs={12} container justifyContent="center">
        <img
          src={`http://${params.address}:5000/testing/getImage/${params.date}.jpg`} //The name of the image file on the backed is the date.
          alt="Expected img"
        />
      </Grid>
      {/* Subtitle which says the date (including the time and number of seconds) at which an image was taken. */}
      <Grid item xs={12} container justifyContent="center">
        <p>Taken on {params.date}s</p>
      </Grid>
    </Grid>
  )
}
