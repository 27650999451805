import * as React from 'react'
import Button from '@mui/material/Button'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link } from 'react-router-dom'

//This component is a button that opens the results for an group of robots in a new tab
export const GroupResultsButton = ({ group }) => {
  return (
    <div className="GroupResultsButton">
      <Button
        component={Link} //This is what type of component it is. In order to make the button open
        // in a new tab, I needed to combine the <Link> component to within the <Button> component.
        to={`/results/${group.name}`} //This is the path to the results page which you can see used in index.js.
        //  The results page is equiped to handle both a groupName and a robotName as its input, so passing just a group name here is fine.
        target="_blank" //This makes it open in a new tab
        startIcon={<CheckCircleIcon />}
        fullWidth
        color="success"
        variant="contained"
      >
        {' '}
        View Group Results{' '}
      </Button>
    </div>
  )
}
