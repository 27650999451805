import * as React from 'react'
import { Robot } from './Robot.jsx'
import { Grid, Box } from '@mui/material'
import { RunTestsButton } from './RunTestsButton.jsx'
import { AddRobotButton } from './AddRobotButton.jsx'
import { DeleteGroupButton } from './DeleteGroupButton.jsx'
import { GroupResultsButton } from './GroupResultsButton.jsx'

//For each group in the groupList, a robotsGroup is created that has a name, and a list of robots underneath it.
//For each robotsGroup you can run a test for all robots in the group, delete the group and all robots within it,
//add a robot to the group, and view results from all robots in the group
export const RobotsGroup = ({ group, groupList, setGroupList }) => {
  return (
    //This box is used for displaying the name of the group and all 4 buttons.
    <div className="RobotsGroup">
      <Box ml={1} mr={2}>
        <h1>
          <Grid container spacing={1} alignItems="center">
            {/* This is for displaying the group name in big text */}
            <Grid item xs={3}>
              <span> {group.name} </span>
            </Grid>

            {/* This use for displaying the buttons in a 2x2 grid pattern */}
            <Grid item xs={8} container justifyContent="flex-end">
              <Grid item xs={5.5} mr={1}>
                <RunTestsButton robots={group.robots} />
              </Grid>
              <Grid item xs={5.5}>
                <AddRobotButton group={group} groupList={groupList} setGroupList={setGroupList} />
              </Grid>
              <Grid item xs={5.5} mr={1}>
                <DeleteGroupButton
                  currentGroup={group}
                  groupList={groupList}
                  setGroupList={setGroupList}
                />
              </Grid>
              <Grid item xs={5.5}>
                <GroupResultsButton group={group} />
              </Grid>
            </Grid>
          </Grid>
        </h1>
      </Box>
      {/* This generates a robot for each of the robots within the group.*/}
      {group.robots.map((robot) => (
        <Robot
          key={robot.address}
          robot={robot}
          groupList={groupList}
          setGroupList={setGroupList}
        />
      ))}
    </div>
  )
}
