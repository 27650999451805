import * as React from 'react'
import axios from 'axios'
import ErrorAlert from './ErrorAlert'
import { IconButton } from '@mui/material'
import { VideoStream } from './VideoStream.jsx'
import { PatientSimulatorPowerButton } from './PatientSimulatorPowerButton.jsx'
import { PatientSimulatorIncrementButton } from './PatientSimulatorIncrementButton.jsx'
import { PatientSimulatorSelectButton } from './PatientSimulatorSelectButton.jsx'
import { PatientSimulatorDecrementButton } from './PatientSimulatorDecrementButton.jsx'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import DialogTitle from '@mui/material/DialogTitle'
import { Grid } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { CaptureImageButton } from './CaptureImageButton'

// This button opens a dialogue which lets the user initiate the calibration on the robot, and then adjust it by viewing whether or not it is centered on the video stream.
export const CalibrateButton = ({ robot, config, setConfig }) => {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState()
  const [isInitialized, setIsInitialized] = React.useState(false)
  const streamWidth = 400
  const streamHeight = 300
  const gridInterfaceHeight = streamHeight / 3

  //This opens the dialogue when the main button is clicked.
  const handleClickOpen = () => {
    setOpen(true)
  }

  // Open is set to false whenever "Finish" is clicked.  Open is also set to close when the user clicks anywhere that isn't on the dialogue.
  const handleClose = () => {
    setOpen(false)
  }

  // This simply hits the /calibration route on the backend. If it does not error out, this means the calibration was successful and the isInitialized variable is set to true.
  const initializeRobot = async () => {
    try {
      await axios.get(`http://${robot.address}:5000/calibration`)
    } catch (err) {
      setError(err.message)
    }
    if (!error) {
      setIsInitialized(true)
    }
  }

  const pushConfig = async (newConfig) => {
    try {
      await axios.patch(`http://${robot.address}:5000/testing/config`, newConfig) //Send the selected config to the device
      const { data: testingConfig } = await axios.get(`http://${robot.address}:5000/testing/config`) //Get the newly set config from the device.
      // (This ensures the page and device are never out of sync)
      setConfig(testingConfig)
      setError()
    } catch (err) {
      setError(err.message)
    }
  }

  // This nudges the robot on the selected axis by the select value.
  const nudgeRobot = (axis, value) => async () => {
    const newConfig = config
    if (axis === 'x') {
      newConfig.xCal = JSON.stringify(Number(newConfig.xCal) + Number(value)) //Adjusted the xCal by the correct value
    } //We know it is the y-axis that is being adjusted.
    else {
      newConfig.yCal = JSON.stringify(Number(newConfig.yCal) + Number(value)) //Adjust the yCal by the correct value
    }

    pushConfig(newConfig)
  }

  const increaseBrightness = async () => {
    const newConfig = config
    newConfig.brightness = JSON.stringify(Number(newConfig.brightness) + 5)

    pushConfig(newConfig)
  }

  const decreaseBrightness = async () => {
    const newConfig = config
    newConfig.brightness = JSON.stringify(Number(newConfig.brightness) - 5)

    pushConfig(newConfig)
  }

  const resetBrightness = async () => {
    const newConfig = config
    newConfig.brightness = JSON.stringify(0)

    pushConfig(newConfig)
  }

  const increaseSaturation = async () => {
    const newConfig = config
    newConfig.saturation = JSON.stringify(Number(newConfig.saturation) + 0.1)

    pushConfig(newConfig)
  }

  const decreaseSaturation = async () => {
    const newConfig = config
    newConfig.saturation = JSON.stringify(Number(newConfig.saturation) - 0.1)

    pushConfig(newConfig)
  }

  const resetSaturation = async () => {
    const newConfig = config
    newConfig.saturation = JSON.stringify(0)

    pushConfig(newConfig)
  }

  const increaseGamma = async () => {
    const newConfig = config
    newConfig.gamma = JSON.stringify(Number(newConfig.gamma) + 0.025)

    pushConfig(newConfig)
  }

  const decreaseGamma = async () => {
    const newConfig = config
    newConfig.gamma = JSON.stringify(Number(newConfig.gamma) - 0.025)

    pushConfig(newConfig)
  }

  const resetGamma = async () => {
    const newConfig = config
    newConfig.gamma = JSON.stringify(0.025)

    pushConfig(newConfig)
  }

  // This is styling for the stream icon. Since I was using this styling 4 times I figured I would save some space by making it a style that can be called.
  const streamIcon = {
    width: streamHeight / 3,
    height: streamHeight / 3,
    color: 'white',
  }

  return (
    <div>
      <ErrorAlert error={error} setError={setError} />
      {/* This is the main button that opens the calibrate dialogue */}
      <Button
        onClick={handleClickOpen}
        startIcon={<OpenWithIcon />}
        fullWidth
        color="secondary"
        variant="contained"
      >
        {' '}
        Calibrate{' '}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Calibrate '}</DialogTitle>
        <DialogContent>
          {/* This initializes the robot. */}
          <Button variant="contained" onClick={initializeRobot} autoFocus>
            Initialize
          </Button>

          <Grid container sx={{ width: streamWidth }}>
            <Grid item xs={12} container alignItems="center" mt={2} mb={1}>
              <Grid item xs={3}>
                Brightness:
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={decreaseBrightness}
                  disabled={config.brightness <= -255}
                >
                  <RemoveIcon />
                </Button>
              </Grid>
              <Grid item xs={1.5} container justifyContent="center">
                {config.brightness}
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={increaseBrightness}
                  disabled={config.brightness >= 255}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item xs={3} container alignItems="center" justifyContent="center">
                <Button size="small" color="success" onClick={resetBrightness}>
                  Reset
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" mb={1}>
              <Grid item xs={3}>
                Saturation:
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={decreaseSaturation}
                  disabled={config.saturation <= 0}
                >
                  <RemoveIcon />
                </Button>
              </Grid>
              <Grid item xs={1.5} container justifyContent="center">
                {Number(config.saturation).toFixed(1)}
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={increaseSaturation}
                  disabled={config.saturation >= 10}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item xs={3} container alignItems="center" justifyContent="center">
                <Button size="small" color="success" onClick={resetSaturation}>
                  Reset
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} container mb={2}>
              <Grid item xs={3}>
                Gamma:
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={decreaseGamma}
                  disabled={config.gamma <= 0.025}
                >
                  <RemoveIcon />
                </Button>
              </Grid>
              <Grid item xs={1.5} container alignItems="center" justifyContent="center">
                {Number(config.gamma).toFixed(3)}
              </Grid>
              <Grid item xs={2} container justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={increaseGamma}
                  disabled={config.gamma >= 255}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item xs={3} container alignItems="center" justifyContent="center">
                <Button size="small" color="success" onClick={resetGamma}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* The video stream already comes with a + symbol in the middle of the stream object. In order to integrate into that however, we need to encapsulate it in a div with its position set to relative. */}
          <div
            style={{ position: 'relative', height: streamHeight + 'px', width: streamWidth + 'px' }}
          >
            <VideoStream robot={robot} streamHeight={streamHeight} streamWidth={streamWidth} />
            {/* This !!isInitialized keeps and of the adjustment arrows from appearing before the initialization is complete. This is a safeguard against sending the robot too many commands.*/}
            {
              // This is the grid styling that positions the arrows all pointing away from the center

              !!isInitialized && (
                <Grid
                  container
                  style={{
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    height: streamHeight,
                    width: streamWidth,
                  }}
                >
                  <Grid container item xs={12} style={{ height: gridInterfaceHeight }}>
                    <Grid item xs={4} container alignItems="start">
                      <CaptureImageButton robot={robot} setError={setError} />
                    </Grid>

                    {/* UP Arrow */}
                    <Grid item xs={4} container justifyContent="center">
                      <IconButton onClick={nudgeRobot(`x`, `-1`)} style={{ padding: 0 }}>
                        <ExpandLess style={streamIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ height: gridInterfaceHeight }}>
                    {/* LEFT Arrow */}
                    <Grid item xs={4} container justifyContent="center">
                      <IconButton onClick={nudgeRobot(`y`, `-1`)} style={{ padding: 0 }}>
                        <ChevronLeft style={streamIcon} />
                      </IconButton>
                    </Grid>
                    {/* This empty grid object is used for correctly spacing things. */}
                    <Grid item xs={4} container justifyContent="center" alignItems="center"></Grid>
                    {/* RIGHT Arrow */}
                    <Grid item xs={4} container justifyContent="center">
                      <IconButton onClick={nudgeRobot(`y`, `1`)} style={{ padding: 0 }}>
                        <ChevronRight style={streamIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    style={{ height: gridInterfaceHeight }}
                  >
                    {/* DOWN Arrow */}
                    <Grid item xs={3} container justifyContent="center">
                      <IconButton onClick={nudgeRobot(`x`, `1`)} style={{ padding: 0 }}>
                        <ExpandMore style={streamIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </div>
          <PatientSimulatorPowerButton address={robot.address} />
          <PatientSimulatorIncrementButton address={robot.address} />
          <PatientSimulatorSelectButton address={robot.address} />
          <PatientSimulatorDecrementButton address={robot.address} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
