import * as React from 'react'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorAlert from './ErrorAlert'
import { updateGroupListsOnRobots, getGroupList } from './Utils.js'

//This button deletes the current group and updates the robotsList of all the other robots that are online.
export const DeleteGroupButton = ({ currentGroup, groupList, setGroupList }) => {
  const [error, setError] = React.useState()

  const submitForm = async () => {
    const newGroupList = groupList
    const groupName = currentGroup.name

    //This finds the group that we are deleting and removes it from the groupList to make the newGroupList
    newGroupList.forEach((group) => {
      if (group.name === groupName) {
        newGroupList.splice(newGroupList.indexOf(group), 1)
      }
    })

    try {
      //This sends out the newGroupList to all of the available robots
      await Promise.all(updateGroupListsOnRobots(newGroupList))

      //Gets the groupList from the robot and updates the current groupList.
      //This is so that the robots and website are always syncronized.
      getGroupList(setGroupList)
    } catch (err) {
      setError(err.message)
    }
  }

  // Returns a button with the above functionality.
  return (
    <div className="DeleteGroupButton">
      <ErrorAlert error={error} setError={setError} />
      {!error && (
        <Button
          startIcon={<DeleteIcon />}
          fullWidth
          color="error"
          variant="contained"
          onClick={submitForm}
        >
          {' '}
          Delete Group{' '}
        </Button>
      )}
    </div>
  )
}
