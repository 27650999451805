import * as React from 'react'
import { RobotsGroup } from './RobotsGroup'
import { AddGroupButton } from './AddGroupButton'
import axios from 'axios'
import Container from '@mui/material/Container'
import ErrorAlert from './ErrorAlert'
import TableLoading from './TableLoading'

//This fetches the list of robots from the robots and creates a list of robots e.g. <RobotsGroup> for each of the groups in the list it receives.
export const GroupList = ({ robotAddress }) => {
  const [groupList, setGroupList] = React.useState()
  const [error, setError] = React.useState()

  //The fetches the list of robots from the raspberry pi and sets the groupList with it. The should be the first request that any new page makes.
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: robots } = await axios(`${robotAddress}:5000/robots`)
        setGroupList(robots)
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      fetchData()
    }
  }, [robotAddress, error])

  return (
    <div className="GroupList">
      <ErrorAlert error={error} setError={setError} />
      {!groupList && !error && <TableLoading />}

      {/* This is some formatting, plus the generation of a <RobotsGroup> for each group present in the groupList */}
      {!!groupList && (
        <Container maxWidth="lg">
          {groupList.map((group) => (
            <RobotsGroup
              key={group.name}
              group={group}
              groupList={groupList}
              setGroupList={setGroupList}
            />
          ))}
          {/* This is a button that appears at the bottom of the main page which allows the addition of groups. */}
          <AddGroupButton groupList={groupList} setGroupList={setGroupList} />
        </Container>
      )}
    </div>
  )
}
