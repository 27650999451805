import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, Box } from '@mui/material'
import axios from 'axios'

// This page fetches and displays the log file that is stored on the robot.
export const LogsPage = () => {
  const params = JSON.parse(useParams().params)

  const [error, setError] = React.useState()
  const [logs, setLogs] = React.useState()

  //Upon rendering, get the log file from the robot
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: logFile } = await axios.get(`http://${params.address}:5000/get_logs`)
        setLogs(logFile) //Set the log file that we received from the robot
      } catch (err) {
        setError(err.message)
      }
    }
    if (!error) {
      fetchData()
    }
  }, [error, params.address])

  //A simple header and then the body of the logs.
  return (
    <Grid container m={3}>
      <Grid item xs={12} container justifyContent="center">
        {' '}
        <p>
          Logs from {params.hostname} at {params.address}
        </p>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Card elevation={5}>
          <Box m={3} style={{ whiteSpace: 'break-spaces' }}>
            {logs}
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}
