import axios from 'axios'

//setGroupList() function is used to fetch the grouplist from the parent robot and then call the setGroupList function that needs to be passed to it.
//This should be called anytime after we push a new groupList to the robots to ensure that if that didn't work, the groupList that the frontend
//is using isn't ever out of sync with the robots.
export function getGroupList(setGroupList) {
  async function getRobots() {
    const robotAddress = process.env.REACT_APP_ROBOT_ADDRESS || 'http://localhost'
    const { data: robots } = await axios.get(`${robotAddress}:5000/robots`)
    setGroupList(robots)
  }
  getRobots()
}

//updateGroupListsOnRobots() is used to update the grouplist on all of the robots with a new groupList.
//This function should be used when, for example, adding/deleting a robot or adding/deleting a group.
//The return value of this function is a array of promises. In order to resolve all of these then, you need to call Promise.all() on this function.
//Calling this function by itself won't execute the axios.put() method unless unless the promises are called.
export function updateGroupListsOnRobots(groupList) {
  const promises = []
  groupList.forEach((group) => {
    group.robots.forEach((robot) => {
      promises.push(axios.put(`http://${robot.address}:5000/robots`, groupList))
    })
  })
  return promises
}
